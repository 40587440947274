import React from "react";
import { Container, TextField } from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import logo from "../../assets/images/easysettle.png";
import { login } from "../../api/API";
import "./Login.css";

export default function Login() {
  const history = useHistory();
  let { Login } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [Login]);

  const [loginData, setLoginData] = React.useState({
    contact: "",
    password: "",
    token: "",
  });

  const handleChange = (event) => {
    setLoginData({
      ...loginData,
      [event.target.name]: event.target.value,
    });
  };

  const handleLogin = () => {
    if (loginData.contact.length == 0 || loginData.password.length == 0) {
      alert("All Field Required");
    }
    login(loginData).then((json) => {
      if (json.status) {
        history.push("/");
      } else {
      }
    });
  };
  return (
    <React.Fragment>
      <Header />
      <Container>
        <div className="theLoginSection">
          <div className="theLoginBox">
            <div className="theForm">
              <div className="theLogo">
                <img src={logo} />
              </div>
              <div className="theFormGroup">
                <p className="label">Contact Number</p>
                <TextField
                  className="theFormControl"
                  placeholder="Contact Number"
                  name="contact"
                  onChange={handleChange}
                />
              </div>
              <div className="theFormGroup">
                <p className="label">Password</p>
                <TextField
                  className="theFormControl"
                  placeholder="Password"
                  type="Password"
                  name="password"
                  onChange={handleChange}
                />
              </div>
              <Link className="forget">Forgot Password?</Link>
              <div className="theAction">
                <a className="theLoginBtn" onClick={() => handleLogin()}>
                  LOGIN
                </a>
                <br />
                <a className="theRegisterBtn" href="/signup">
                  REGISTER ACCOUNT
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
