import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  LinearProgress,
  styled,
  linearProgressClasses,
  Typography,
} from "@material-ui/core";
import { Link, useLocation, useHistory } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Home.css";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Slider from "react-slick";
import {
  get_category,
  get_recommend_product_by_category,
  get_recommended_product_web,
  search_warranty,
  get_special_deal_product,
  get_banner,
  get_announcement,
  get_new_launching_products,
  get_shocking_sale_products,
  get_daily_discover_products,
} from "../../api/API";

import banner001 from "../../assets/images/home-banner1.png";
// import banner001 from "../../assets/images/home-banner.png";
import banner002 from "../../assets/images/warranty-portal.png";
import banner003 from "../../assets/images/service-portal.png";
import logo from "../../assets/images/easysettle.png";
import shipping from "../../assets/images/shipping.png";
import setting from "../../assets/images/setting.png";
import tag from "../../assets/images/tag.png";
import prod001 from "../../assets/images/wall-mounted.png";
import fresh from "../../assets/images/fresh.png";
import job from "../../assets/images/bannerServices.png";
import WarrantyRegisterModal from "./WarrantyRegisterModal";
import aircorn from "../../assets/images/aircon.png";
import waterheater from "../../assets/images/waterheater.png";
import Product001 from "../../assets/images/product01.jpg";
import Product002 from "../../assets/images/product02.jpg";
import Product003 from "../../assets/images/product03.jpg";
import Product004 from "../../assets/images/product04.jpg";
import Product005 from "../../assets/images/product05.jpg";
import Product006 from "../../assets/images/product06.jpg";
import refer001 from "../../assets/images/refer.png";
import Paragraph from "../../components/Typography/Paragraph";

import { makeStyles } from "@material-ui/core/styles";
import category001 from "../../assets/images/air-conditioner.png";
import category002 from "../../assets/images/coffee-maker.png";
import category003 from "../../assets/images/heater.png";
import category004 from "../../assets/images/system.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 15,
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 800],
  },
  bar: {
    borderRadius: 0,
    backgroundColor: theme.palette.type === "light" ? "#4ac6e9" : "#308fe8",
  },
  text: {
    position: "absolute",
    top: "53%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#fff",
    fontWeight: "500",
    fontSize: "12px",
    letterSpacing: ".1em",
  },
}));

export default function Home() {
  const history = useHistory();
  let { Home } = useLocation();
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [Home]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // responsive: [
    //   {
    //     breakpoint: 769,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //       initialSlide: 3,
    //       infinite: true,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 2,
    //       initialSlide: 2,
    //       infinite: true,
    //       arrows: false,
    //     },
    //   },
    // ],
  };
  const settings2 = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,

          dots: false,
        },
      },
    ],
  };
  const [registerWarranty, setRegisterWarranty] = useState(false);
  const [next, setNext] = useState(false);

  const handleClose = () => {
    setRegisterWarranty(false);
    setNext(false);
  };

  // handle filter
  const [form, setForm] = useState({
    serial_no: "",
  });

  const handleChangeField = (event) => {
    event.persist();
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleFilterWarranty = async () => {
    var res = await search_warranty({
      ...form,
    });
    if (res.status) {
      // console.log(res.data[0].product_warranty_id, {...form})
      history.push("/warrantydetail/" + res.product_warranty_id[0], {
        ...form,
      });
    } else {
      alert(res.message);
    }
  };

  // TIMER

  const [category, setCategory] = useState([]);
  const [recommend, setRecommend] = useState([]);
  const [specialDeal, setSpecialDeal] = useState(null);
  const [recommendcategory_cooling, setRecommendCategory_Cooling] = useState(
    []
  );
  const [recommendcategory_kitchen, setRecommendCategory_Kitchen] = useState(
    []
  );

  const [banner, setBanner] = React.useState([]);
  const [announcement, setAnnouncement] = React.useState([]);
  const [newProductList, setNewProductList] = React.useState([]);
  const [shockingProductList, setShockingProductList] = React.useState([]);
  const [dailyProductList, setDailyProductList] = React.useState([]);

  useEffect(() => {
    let post_data_cooling = {
      category_id: 1,
    };
    let post_data_kitchen = {
      category_id: 24,
    };
    get_category().then((json) => {
      var temp_data = [];
      for (var i = 0; i < 4; i++) {
        temp_data.push(json.data[i]);
      }
      // setCategory(json.data);
      setCategory(temp_data);
    });
    get_recommended_product_web().then((json) => {
      setRecommend(json.data);
    });
    get_recommend_product_by_category(post_data_cooling).then((json) => {
      setRecommendCategory_Cooling(json.data);
    });
    get_recommend_product_by_category(post_data_kitchen).then((json) => {
      setRecommendCategory_Kitchen(json.data);
    });
    get_special_deal_product({ is_see_all: 1 }).then((json) => {
      setSpecialDeal(json.data);
    });
    get_banner().then((res) => {
      setBanner(res.data);
    });

    get_announcement().then((res) => {
      setAnnouncement(res.data);
    });

    get_new_launching_products().then((res) => {
      setNewProductList(res.data);
    });

    get_shocking_sale_products().then((res) => {
      setShockingProductList(res.data);
    });

    get_daily_discover_products().then((res) => {
      setDailyProductList(res.data);
    });
  }, []);

  //

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // Function to calculate the remaining time
  const getCountdown = () => {
    const timeRemaining = new Date("2024-12-28") - new Date();
    if (timeRemaining > 0) {
      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
      const seconds = Math.floor((timeRemaining / 1000) % 60);
      setCountdown({ days, hours, minutes, seconds });
    }
  };

  useEffect(() => {
    // Update the countdown every second
    const intervalId = setInterval(() => {
      getCountdown();
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run effect only once after mount

  return (
    <React.Fragment>
      <Header />
      <Box>
        <Slider {...settings}>
          {/* {specialDeal != null &&
            specialDeal.map((item) => (
              <div>
                <Link to={`../productdetail/` + item.product_id}>
                  <div className="c-theSliderBox">
                    <img src={item.product_image} />
                    {item.brand != null && (
                      <p className="discount">{item.brand}</p>
                    )}
                    <p className="sub">{item.name}</p>
                  </div>
                </Link>
              </div>
            ))} */}
          {/* {banner.map((item) => (
            <Box sx={{ width: "100%" }}>
              <img src={item.banner_image} style={{ width: "100%" }} />
            </Box>
          ))} */}
          <Box sx={{ width: "100%" }}>
            <img src={banner001} style={{ width: "100%" }} />
          </Box>

          {/* {recommendcategory_cooling.map((item) => (
              <div>
                <Link to={`../productdetail/${item.product_id}`}>
                  <div className="c-theSliderBox">
                    <img src={item.product_image} />
                    <p className="discount">{item.brand}</p>
                    <p className="sub">{item.name}</p>
                  </div>
                </Link>
              </div>
            ))} */}
        </Slider>
      </Box>
      {/* <div className="c-banner">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={7}>
            <div className="thebanner001">
              <img src={banner001} />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} className="theColumn">
            <div className="thebanner002">
              <img src={banner002} />
              <div className="thedetail">
                <p className="bold">
                  <span
                    style={{ marginBottom: "1px", display: "block" }}
                    className="color-blueBold"
                  >
                    EasySettle
                  </span>
                  Warranty Portal
                </p>
                <Link
                  onClick={() => {
                    setRegisterWarranty(true);
                  }}
                  className="btn-primary"
                >
                  Register Now
                </Link>
                <div className="theFlexForm">
                  <div className="theFFtextInput">
                    <TextField
                      className="form-control"
                      placeholder="Serial Number"
                      name="serial_no"
                      // value={form.serial_no}
                      // onChange={handleChangeField}
                    />
                  </div>
                  <div className="theFFbutton">
                    <Link
                      onClick={() => handleFilterWarranty()}
                      className="btn-primary"
                    >
                      Check
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <Link className="thebanner003" to="/joblist">
              <img src={banner003} />
              <div className="thedetail_">
                <p>Get a job from our available list</p>
                <img src={logo} />
                <span>
                  Go to Job Portal <KeyboardArrowRightIcon />
                </span>
              </div>
            </Link>
          </Grid>
        </Grid>
      </div> */}
      <Container>
        {/* <div className="c-banner">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={7}>
              <div className="thebanner001">
                <img src={banner001} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5} className="theColumn">
              <div className="thebanner002">
                <img src={banner002} />
                <div className="thedetail">
                  <p className="bold">
                    <span
                      style={{ marginBottom: "1px", display: "block" }}
                      className="color-blueBold"
                    >
                      EasySettle
                    </span>
                    Warranty Portal
                  </p>
                  <Link
                    onClick={() => {
                      setRegisterWarranty(true);
                    }}
                    className="btn-primary"
                  >
                    Register Now
                  </Link>
                  <div className="theFlexForm">
                    <div className="theFFtextInput">
                      <TextField
                        className="form-control"
                        placeholder="Serial Number"
                        name="serial_no"
                        // value={form.serial_no}
                        // onChange={handleChangeField}
                      />
                    </div>
                    <div className="theFFbutton">
                      <Link
                        onClick={() => handleFilterWarranty()}
                        className="btn-primary"
                      >
                        Check
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <Link className="thebanner003" to="/joblist">
                <img src={banner003} />
                <div className="thedetail_">
                  <p>Get a job from our available list</p>
                  <img src={logo} />
                  <span>
                    Go to Job Portal <KeyboardArrowRightIcon />
                  </span>
                </div>
              </Link>
            </Grid>
          </Grid>
        </div> */}
        <div className="c-thelist">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={4}>
              <div className="thebox">
                <div className="theicon">
                  <img src={shipping} />
                </div>
                <div className="thedetail">
                  <p>Free Delivery</p>
                  <span>within Johor Bahru Area</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div className="thebox">
                <div className="theicon">
                  <img src={setting} />
                </div>
                <div className="thedetail">
                  <p>Free Delivery</p>
                  <span>within Johor Bahru Area</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div className="thebox_">
                <div className="theicon">
                  <img src={tag} />
                </div>
                <div className="thedetail">
                  <p>Free Delivery</p>
                  <span>within Johor Bahru Area</span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="c-thelist2">
          <h3 style={{ textTransform: "uppercase" }}>Featured Categories</h3>
          <Grid container spacig={3}>
            {/* {category.map((item) => (
              <Grid item xs={6} sm={3} md={3}>
                <Link
                  className="theListBox"
                  to={`../category/${item.category_id}`}
                >
                  <div className="theDetail">
                    <img src={item.image} />
                    <p>{item.name}</p>
                  </div>
                </Link>
              </Grid>
            ))} */}

            <Grid item xs={6} sm={3} md={3}>
              <Link className="theListBox" to={`../category/1`}>
                <div className="theDetail">
                  <img src={category001} />
                  <p>Aircond</p>
                </div>
              </Link>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <Link className="theListBox" to={`../category/1`}>
                <div className="theDetail">
                  <img src={category002} />
                  <p>Kitchen Appliance</p>
                </div>
              </Link>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <Link className="theListBox" to={`../category/1`}>
                <div className="theDetail">
                  <img src={category003} />
                  <p>Storage Water Heater </p>
                </div>
              </Link>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <Link className="theListBox" to={`../category/1`}>
                <div className="theDetail">
                  <img src={category004} />
                  <p>C.Ducted</p>
                </div>
              </Link>
            </Grid>
          </Grid>
        </div>

        <div className="c-thelist2">
          <h3 style={{ textTransform: "uppercase" }}>Sinma Announcement</h3>
          <Slider {...settings2}>
            {/* {announcement.map((item) => (
              <Box style={{ width: "100%", margin: "0 auto" }}>
                <img style={{ width: "92%" }} src={item.image} />
                <p className="sub">{item.name}</p>
              </Box>
            ))} */}
            <Box style={{ width: "100%", margin: "0 auto" }}>
              <img style={{ width: "92%" }} src={Product006} />
              <p className="sub">OUR STORE LOCATION</p>
            </Box>
            <Box style={{ width: "100%", margin: "0 auto" }}>
              <img style={{ width: "92%" }} src={refer001} />
              <p className="sub">REFER FRIENDS TO GET COINS</p>
            </Box>
          </Slider>
        </div>
      </Container>
      <div className="c-thelist4">
        <div className="c-theimg">
          <div className="title">
            <p>STAY COOL</p>
            <p>
              <span>STAY</span> FRESH
            </p>
          </div>
          <img src={fresh} />
        </div>
        <div className="c-slider_">
          <Slider {...settings2}>
            {/* {specialDeal != null &&
              specialDeal.map((item) => (
                <div>
                  <Link to={`../productdetail/` + item.product_id}>
                    <div className="c-theSliderBox">
                      <img src={item.product_image} />
                      <p className="price">RM {item.discount_price}</p>
                      {item.brand != null && (
                        <p className="discount">{item.brand}</p>
                      )}
                      <p className="sub">{item.name}</p>
                    </div>
                  </Link>
                </div>
              ))} */}
            <div>
              <Link to={`../productdetail/` + 1}>
                <div className="c-theSliderBox">
                  <img src={Product001} />
                  {/* <p className="price">RM {item.discount_price}</p> */}
                  {/* {item.brand != null && (
                    <p className="discount">{item.brand}</p>
                  )} */}
                  <p className="sub">Air Conditioner</p>
                </div>
              </Link>
            </div>
            <div>
              <Link to={`../productdetail/` + 1}>
                <div className="c-theSliderBox">
                  <img src={Product001} />
                  {/* <p className="price">RM {item.discount_price}</p> */}
                  {/* {item.brand != null && (
                    <p className="discount">{item.brand}</p>
                  )} */}
                  <p className="sub">Air Conditioner</p>
                </div>
              </Link>
            </div>
          </Slider>
        </div>
      </div>

      <Container>
        <div className="theContructor">
          <Link className="theCardContrustor" to="/joblist">
            <div className="theCardIMG">
              <img src={aircorn} />
            </div>
            <p className="title">Air Conditional</p>
            <p className="btnDetail">View Showcase</p>
          </Link>
          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Link className="theCardContrustor" to="/joblist">
                <div className="theCardIMG">
                  <img src={aircorn} />
                </div>
                <p className="title">Air Conditional</p>
                <p className="btnDetail">View Showcase</p>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Link className="theCardContrustor" to="/constructor">
                <div className="theCardIMG">
                  <img src={waterheater} />
                </div>
                <p className="title">Water Heater</p>
                <p className="btnDetail">View Showcase</p>
              </Link>
            </Grid>
          </Grid> */}
        </div>
      </Container>
      <br />
      <br />
      <Container>
        <h3 style={{ textTransform: "uppercase" }}>New Launching Products</h3>
        <Slider {...settings2}>
          {/* {newProductList.map((item) => (
            <Link to={`../productdetail/` + item.product_id}>
              <Box>
                <img style={{ width: "95%" }} src={item.image} />
                <Paragraph
                  bold="700"
                  margin=".6rem 0"
                  style={{ color: "#317dbd", fontSize: "18px" }}
                  center
                >
                  RM {item.price}
                </Paragraph>
              </Box>
            </Link>
          ))} */}
          <Link to={`../productdetail/` + 1}>
            <Box>
              <img style={{ width: "95%" }} src={Product003} />
              <Paragraph
                bold="700"
                margin=".6rem 0"
                style={{ color: "#317dbd", fontSize: "18px" }}
                center
              >
                RM 100.00
              </Paragraph>
            </Box>
          </Link>
          <Link to={`../productdetail/` + 1}>
            <Box>
              <img style={{ width: "95%" }} src={Product004} />
              <Paragraph
                bold="700"
                margin=".6rem 0"
                style={{ color: "#317dbd", fontSize: "18px" }}
                center
              >
                RM 100.00
              </Paragraph>
            </Box>
          </Link>
        </Slider>
      </Container>

      <br />
      <br />
      <Container>
        <Box
          style={{
            display: "flex",
            margin: "0 0 1rem 0",
            alignItems: "center",
          }}
        >
          <h3 style={{ margin: "0 1rem 0 0" }}>
            <span style={{ color: "#317dbd" }}>SHOCKING</span> SALE
          </h3>
          <Box style={{ display: "flex", alignItems: "center" }}>
            {countdown.days > 0 && (
              <Box
                style={{
                  background: "#000",
                  padding: ".4rem",
                }}
              >
                <p style={{ color: "#fff", margin: 0 }}>{countdown.days}</p>
              </Box>
            )}

            <Box
              style={{
                background: "#000",
                padding: ".4rem",
              }}
            >
              <p style={{ color: "#fff", margin: 0 }}>{countdown.hours}</p>
            </Box>

            <p style={{ color: "#000", margin: "0 0.4rem" }}>:</p>

            <Box
              style={{
                background: "#000",
                padding: ".4rem",
              }}
            >
              <p style={{ color: "#fff", margin: 0 }}>{countdown.minutes}</p>
            </Box>

            {countdown.days <= 0 && (
              <>
                <p style={{ color: "#000", margin: "0 0.4rem" }}>:</p>
                <Box
                  style={{
                    background: "#000",
                    padding: ".4rem",
                  }}
                >
                  <p style={{ color: "#fff", margin: 0 }}>
                    {countdown.seconds}
                  </p>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
            <Grid>

            </Grid>
        </Box>
        <Box sx={{ display: { xs: "none", lg: "block" } }}>

        </Box>
        <Slider {...settings2}>
          {/* {shockingProductList.map((item) => (
            <Link to={`../productdetail/` + item.product_id}>
              <Box className="theBox">
                <img style={{ width: "95%" }} src={item.image} />
                <Paragraph
                  bold="700"
                  margin=".6rem 0"
                  style={{ color: "#317dbd", fontSize: "18px" }}
                  center
                >
                  RM {item.price}
                </Paragraph>
                <Box width="100%" position="relative">
                  <LinearProgress
                    variant="determinate"
                    value={50}
                    classes={{
                      root: classes.root,
                      bar: classes.bar,
                    }}
                  />
                  <Typography className={classes.text}>SELLING FAST</Typography>
                </Box>
              </Box>
            </Link>
          ))} */}
          <Link to={`../productdetail/` + 1}>
            <Box className="theBox">
              <img style={{ width: "95%" }} src={Product004} />
              <Paragraph
                bold="700"
                margin=".6rem 0"
                style={{ color: "#317dbd", fontSize: "18px" }}
                center
              >
                RM 100.00
              </Paragraph>
              <Box width="100%" position="relative">
                <LinearProgress
                  variant="determinate"
                  value={50}
                  classes={{
                    root: classes.root,
                    bar: classes.bar,
                  }}
                />
                <Typography className={classes.text}>SELLING FAST</Typography>
              </Box>
            </Box>
          </Link>
          <Link to={`../productdetail/` + 1}>
            <Box className="theBox">
              <img style={{ width: "95%" }} src={Product004} />
              <Paragraph
                bold="700"
                margin=".6rem 0"
                style={{ color: "#317dbd", fontSize: "18px" }}
                center
              >
                RM 100.00
              </Paragraph>
              <Box width="100%" position="relative">
                <LinearProgress
                  variant="determinate"
                  value={50}
                  classes={{
                    root: classes.root,
                    bar: classes.bar,
                  }}
                />
                <Typography className={classes.text}>SELLING FAST</Typography>
              </Box>
            </Box>
          </Link>
        </Slider>
      </Container>
      <br />
      <br />
      <Container>
        <h3 style={{ textTransform: "uppercase" }}>Daily Discover</h3>
        <Grid container spacing={3}>
          {/* {dailyProductList.map((item) => (
            <Grid xs={6} sm={3} md={4}>
              <Link to={`../productdetail/` + item.product_id}>
                <Box>
                  <img style={{ width: "95%" }} src={item.image} />
                  <Paragraph
                    bold="700"
                    margin=".6rem 0"
                    style={{ color: "#317dbd", fontSize: "18px" }}
                    center
                  >
                    RM {item.price}
                  </Paragraph>
                </Box>
              </Link>
            </Grid>
          ))} */}
          <Grid xs={6} sm={3} md={3}>
            <Link to={`../productdetail/` + 1}>
              <Box>
                <img style={{ width: "95%" }} src={Product002} />
                <Paragraph
                  bold="700"
                  margin=".6rem 0"
                  style={{ color: "#317dbd", fontSize: "18px" }}
                  center
                >
                  RM 100.00
                </Paragraph>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Container>

      <Footer />
      <WarrantyRegisterModal
        open={registerWarranty}
        next={next}
        setNext={setNext}
        handleClose={handleClose}
      />
    </React.Fragment>
  );
}
