import React from "react";

import { useHistory } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Paragraph from "../../components/Typography/Paragraph";
import { Box, Container, Grid } from "@material-ui/core";

import Loader from "react-js-loader";
import { search_product } from "../../api/API";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function SearchAll() {
  const navigate = useHistory();

  const location = useLocation();

  const pathSegment = location.pathname.split("/")[2];

  console.log(location);

  // API

  React.useEffect(() => {
    window.localStorage.removeItem("page");
  }, []);

  const [loading, setLoading] = React.useState(true);
  const [searchKey, setSearchKey] = React.useState("");
  const [sProduct, setSProduct] = React.useState([]);

  //   const { loading, searchKey, sProduct } = useSelector(
  //     (state) => state.products
  //   );

  // SEARCH
  React.useEffect(() => {
    search_product({ keyword: pathSegment }).then((res) => {
      if (res.status) {
        setSProduct(res.data);
        setLoading(false);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <Header />

      <Container>
        <Box>
          <Paragraph
            color="#317dbd"
            margin="0 0 3rem 0"
            bold="700"
            center
            style={{ fontSize: "24px" }}
          >
            Search Results
          </Paragraph>
        </Box>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader type="snipper-default" bgColor={"#878787"} size={30} />
          </div>
        ) : (
          <Grid container spacing={3}>
            {sProduct.map((item) => (
              <Grid item xs={12} md={3}>
                <Box>
                  <a
                    style={{ position: "relative" }}
                    href={`../productdetail/${item.product_id}`}
                  >
                    <Box
                      sx={{
                        background: "#F9F9F9",
                        padding: "1rem",
                      }}
                    >
                      <Box sx={{ width: "100%", margin: "0 auto" }}>
                        <img
                          style={{
                            width: "100%",
                            objectFit: "contain",
                          }}
                          src={item.product_image}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ padding: "1rem 0" }}>
                      <Paragraph color="#707070" size="14px" margin=".4rem 0">
                        {item.category}
                      </Paragraph>
                      <Paragraph
                        color="#707070"
                        bold="700"
                        margin="0 0 .4rem 0"
                      >
                        {item.name}
                      </Paragraph>
                      <Paragraph color="#707070" bold="700">
                        RM {item.min_price} - RM {item.max_price}
                      </Paragraph>
                    </Box>
                  </a>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
      <Footer />
    </React.Fragment>
  );
}
