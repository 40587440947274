import axios from "axios";
// const host = 'http://178.128.120.86/easysettle_admin/';
const host = "https://easysettle.com.my/admin/";
const access_endpoint_link = host + "API/";

const format_request = (postparam) => {
  if (postparam) {
    let token = window.localStorage.getItem("token");
    if (token) {
      postparam["token"] = token;
    }
  }
  let body = "";
  for (let k in postparam) {
    body += encodeURI(k) + "=" + encodeURI(postparam[k]) + "&";
  }
  var postOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: body,
  };
  return postOptions;
};

export const login = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "login",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    window.localStorage.setItem("token", json.data.token);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      alert(json.message);
    }
    return false;
  }
};
export const register = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "register",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    window.localStorage.setItem("token", json.data.token);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      alert(json.message);
    }
    return false;
  }
};

const api_post = async (postparam = {}, end_point) => {
  var data = await format_request(postparam);
  if (data) {
    var response = await fetch(access_endpoint_link + end_point, data);
    var json = await response.json();
    return json;
  }
};

export const get_brand = async (postparam) => {
  return api_post(postparam, "get_brand");
};

export const get_faq = async (postparam) => {
  return api_post(postparam, "get_faq");
};

export const get_product = async (postparam) => {
  return api_post(postparam, "get_product");
};

export const get_recommended_product_web = async (postparam) => {
  return api_post(postparam, "get_recommended_product_web");
};

export const get_recommend_product_by_category = async (postparam) => {
  return api_post(postparam, "get_recommend_product_by_category");
};

export const get_sub_category = async (postparam) => {
  return api_post(postparam, "get_sub_category");
};

export const get_subcategory_by_category = async (postparam) => {
  return api_post(postparam, "get_subcategory_by_category");
};

export const get_category_by_id = async (postparam) => {
  return api_post(postparam, "get_category_by_id");
};

export const get_product_by_category = async (postparam) => {
  return api_post(postparam, "get_product_by_category");
};

export const get_product_detail = async (postparam) => {
  return api_post(postparam, "get_product_detail");
};

export const get_product_variant = async (postparam) => {
  return api_post(postparam, "get_product_variant");
};

export const get_product_variant_selection = async (postparam) => {
  return api_post(postparam, "get_product_variant_selection");
};

export const get_price_type = async (postparam) => {
  return api_post(postparam, "get_price_type");
};

export const get_installment_period = async (postparam) => {
  return api_post(postparam, "get_installment_period");
};

export const calculate_checkout = async (postparam) => {
  return api_post(postparam, "calculate_checkout");
};

export const purchase = async (postparam) => {
  return api_post(postparam, "purchase");
};

export const get_area = async (postparam) => {
  return api_post(postparam, "get_area");
};

export const get_service = async (postparam) => {
  return api_post(postparam, "get_service");
};

export const register_warranty = async (postparam) => {
  return api_post(postparam, "register_warranty");
};

export const search_warranty = async (postparam) => {
  return api_post(postparam, "search_warranty");
};

export const filter_product = async (postparam) => {
  return api_post(postparam, "filter_product");
};

export const get_profile = async (postparam) => {
  return api_post(postparam, "get_profile");
};

export const edit_profile = async (postparam) => {
  return api_post(postparam, "edit_profile");
};

export const get_orderhistory = async (postparam) => {
  return api_post(postparam, "get_orderhistory");
};

export const get_orderhistory_detail = async (postparam) => {
  return api_post(postparam, "get_orderhistory_detail");
};

export const calculate_cart = async (postparam) => {
  return api_post(postparam, "calculate_cart");
};

export const get_delivery_slot = async (postparam) => {
  return api_post(postparam, "get_delivery_slot");
};

export const get_user_voucher = async (postparam) => {
  return api_post(postparam, "get_user_voucher");
};

export const get_state = async (postparam) => {
  return api_post(postparam, "get_state");
};

export const get_city = async (postparam) => {
  return api_post(postparam, "get_city");
};

export const get_user_wallet = async (postparam) => {
  return api_post(postparam, "get_user_wallet");
};

export const get_category = async (postparam) => {
  return api_post(postparam, "get_category");
};

export const get_service_list = async (postparam) => {
  return api_post(postparam, "get_service_list");
};

export const get_service_detail = async (postparam) => {
  return api_post(postparam, "get_service_detail");
};

export const get_special_deal_product = async (postparam) => {
  return api_post(postparam, "get_special_deal_product");
};

export const search_product = async (postparam) => {
  return api_post(postparam, "search_product");
};

export const get_banner = async (postparam) => {
  return api_post(postparam, "get_banner");
};

export const get_announcement = async (postparam) => {
  return api_post(postparam, "get_announcement");
};

export const get_new_launching_products = async (postparam) => {
  return api_post(postparam, "get_new_launching_products");
};

export const get_shocking_sale_products = async (postparam) => {
  return api_post(postparam, "get_shocking_sale_products");
};

export const get_daily_discover_products = async (postparam) => {
	return api_post(postparam, "get_daily_discover_products");
  };

export const upload_proof_image = async (postparam) => {
  let res = await axios.post(
    access_endpoint_link + "upload_proof_image",
    postparam
  );
  if (res) {
    return res.data;
  } else {
    return false;
  }
};
